body,
p {
  font-family: Tinos;
}
a {
  font-size: inherit;
}
@media (min-width: 1680px) {
  .container {
    max-width: 1620px;
  }
}
@media (min-width: 1400px) {
  p {
    font-size: 1rem;
    // font-size: 18px;
  }
  h1,
  .h1 {
    // font-size: 72px;
    font-size: 4rem;
    font-family: Arsenal;
  }
  h2,
  .h2 {
    // font-size: 48px;
    font-size: 2.667rem;
    font-family: Arsenal;
  }
  h3,
  .h3 {
    // font-size: 36px;
    font-size: 2rem;
    font-family: Arsenal;
  }
  h4,
  .h4 {
    // font-size: 32px;
    font-size: 1.778rem;
  }
  h5,
  .h5 {
    // font-size: 24px;
    font-size: 1.333rem;
  }
  h6,
  .h6 {
    // font-size: 20px;
    font-size: 1.111rem;
  }
}
