$primary: #4133b7;
$secondary: #160c69;
$success: #fff5c3;
$info: #c5c0f0;
$warning: #ffdd2b;
$danger: #a69d0e;
$black16: rgba(0, 0, 0, 0.16);
$primary50: rgba(65, 51, 183, 0.5);
$primary16: rgba(65, 51, 183, 0.16);
$primary16w: #e1dff3;
$info50: rgba(197, 192, 240, 0.5);
$info25: rgba(197, 192, 240, 0.25);
$secondary50: rgba(22, 12, 105, 0.5);
$theme-green: #33b737;
$theme-red: #b73333;
$theme-blue: #2b8eff;
$shadowColor: $black16;
$lightBlue: #cbe3fa;
$lightBlue20: rgba(203, 227, 250, 0.2);
$theme-cyan: #2bffb7;

$pure-red: #ff2b2b;

$warning25: rgba(255, 221, 43, 0.25);
$theme-green25: rgba(106, 255, 43, 0.25);
$theme-blue25: rgba(43, 142, 255, 0.25);
$theme-cyan25: rgba(43, 255, 184, 0.25);

$warning25w: #fff6cb;
$theme-green25w: #daffcb;
$theme-blue25w: #cbe3ff;
$theme-cyan25w: #cbffee;

@media (min-width: 1200px) {
  :root {
    // font-size: 18px;
    font-size: 16px;
  }
}

$grid-gutter-width: 40px;
$small-font: 0.7778rem; // 14px;
$small-font-2: 0.8889rem; // 16px;
$tiny-font: 12px;

$mainfont: "Arsenal", sans-sarif;
$subfont: "Tinos", sans-sarif;
