.lessons-scroller {
  height: 100%;
}

.scroller-wrapper {
  position: relative;
  overflow: hidden scroll;
  width: calc(100% + 35px);
  padding-right: 15px;
  height: 100%;
}

.top-fix {
  position: sticky;
  top: 0;
  padding-right: 5px;
  padding-bottom: 5px;
  background: #fff;
}

.manage-card-inner-body {
  overflow: hidden;
}
