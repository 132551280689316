.not-found-container {
  height: calc(100vh - 208px);
  @media (min-width: 1100px) {
    h1 {
      font-size: 13vmin;
    }
  }
  @media (max-width: 767px) {
    height: calc(100vh - 268px);
  }
  @media (max-width: 520px) {
    height: calc(100vh - 268px);
  }
}
