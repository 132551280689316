.work-plan-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & .work-plan-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 7px solid $color-dashboard-sidbar;
    border-radius: 25px;
    width: 462px;
    height: 273px;

    & .button-upload {
      margin-top: 30px;
    }

    & .work-plan-inputes {
      & > div:first-child {
        margin-bottom: 10px;
      }
    }

    & .work-plan-button-container {
    }
  }
}
