footer {
  background: #90d23d;
  padding: 0 60px;
  .footer-logo a {
    font-size: 38px;
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  .footer-left {
    display: flex;
    align-items: center;
    padding: 30px 0;

    > * {
      padding: 0 20px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .copy {
    color: #fff;
    font-size: $tiny-font;
    margin-bottom: 0;
    a {
      color: inherit;
    }
  }
  .footer-right {
    display: flex;
    align-items: center;
  }
  .icon {
    img {
      max-width: 30px;
      max-height: 30px;
      width: 50px;
    }
    button {
      background: transparent;
      outline: none;
      border: none;
      padding: 15px 30px;
      cursor: pointer;
    }
  }
}
@media (max-width: 520px) {
  footer {
    .footer-logo {
      width: 80px;
      margin-right: 20px;
    }
    .icon {
      button {
        padding: 15px;
      }
    }
  }
}
