/* Set default font-family */
// #editor-container {
//     font-family: "Aref Ruqaa";
//     font-size: 18px;
//     height: 375px;
// }

/* Set dropdown font-families */
.ql-container {
  height: calc(100% - 40px);
}
.ql-snow {
  &.ql-toolbar,
  &.ql-container {
    border: none;
  }
}

#toolbar-container .ql-font span[data-label="Arsenal"]::before {
  font-family: $mainfont;
}
#toolbar-container .ql-font span[data-label="Tinos"]::before {
  font-family: $subfont;
}

/* Set content font-families */
.ql-editor {
  font-family: $mainfont;
}
.ql-font-Arsenal {
  font-family: $mainfont;
}
.ql-font-Tinos {
  font-family: $subfont;
}
/* We do not set Aref Ruqaa since it is the default font */
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
  .ql-picker-label {
    &:before {
      content: attr(data-value) !important;
    }
  }
}

.text-editor {
  .quill {
    height: 100%;
  }
}
