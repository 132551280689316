.calendar-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &:not(.year-view-wrapper) {
    height: 0;
    position: relative;
    padding-top: 100%;
    > div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  > div {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .fc-bg {
      bottom: -5px;
    }
    // &:not(.year-view-wrapper){

    // }
    table {
      width: 100%;
      text-align: center;
      td,
      th {
        width: calc(100% / 7);
        a,
        span {
          width: 100%;
          display: block;
          text-align: center;
          font-family: $mainfont;
          color: $secondary;
          box-sizing: border-box;
        }
      }
      th {
        span {
          font-weight: bold;
          color: $primary;
        }
      }
      .fc-other-month {
        opacity: 0;
      }
      .fc .fc-head .fc-widget-header {
        margin-top: 0.2em;
      }
      .fc-event {
        &.math_event {
          background-color: $warning25;
          border: 1px solid $warning25;
        }
        &.english_event {
          background-color: $theme-cyan25;
          border: 1px solid $theme-cyan25;
        }
        &.history_of_ukraine_event {
          background-color: $theme-green25;
          border: 1px solid $theme-green25;
        }
        &.international_history_event {
          background-color: $theme-blue25;
          border: 1px solid $theme-blue25;
        }
        &.live_event {
          background-color: transparent;
          display: flex;
          justify-content: space-between;
          &::after {
            content: "Live";
            display: block;
            width: 30px;
            line-height: 1.7em;
            font-size: 0.7em;
          }
          & > .fc-content {
            width: calc(100% - 30px);
            padding-right: 8px;
            &::after {
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              background: $pure-red;
              border-radius: 50%;
              position: absolute;
              top: 6px;
              // bottom: 0;
              right: 0;
              margin: auto;
            }
          }
        }
        &.exam_event {
          display: flex;
          justify-content: space-between;
          &::after {
            content: "exam";
            display: block;
            width: 30px;
            line-height: 1.7em;
            font-size: 0.7em;
          }
          & > .fc-content {
            width: calc(100% - 30px);
            padding-right: 8px;
            &::after {
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              background: $warning;
              border-radius: 50%;
              position: absolute;
              top: 6px;
              // top: 0;
              // bottom: 0;
              right: 0;
              margin: auto;
            }
          }
        }
        .fc-time {
          display: none;
        }
      }
    }

    h2 {
      font-size: 1.1rem;
      margin-bottom: 0;
      padding-left: 10px;
      font-weight: bold;
      color: $secondary;
      font-family: $subfont;
    }
  }
  .fc-month-view .fc-scroller {
    height: auto !important;
  }
  .fc-toolbar {
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
}
.year-view-wrapper {
  > div {
    width: 23%;
    min-width: 320px;
    .fc-content-skeleton tbody {
      display: none;
    }
    table {
      .has-event {
        background-color: $primary16;

        &.math_event {
          background-color: $warning25;
        }
        &.english_event {
          background-color: $theme-cyan25;
        }
        &.history_of_ukraine_event {
          background-color: $theme-green25;
        }
        &.international_history_event {
          background-color: $theme-blue25;
        }
      }
    }
    .fc-day-number {
      cursor: pointer;
      &:hover {
        background-color: $black16;
      }
    }
    .fc-other-month .fc-day-number {
      pointer-events: none;
    }
    .fc-basic-view .fc-day-number {
      padding-bottom: 50%;
    }
  }
}
.month-view-wrapper {
  > div {
    width: 100%;
  }

  .fc-bg {
    height: 100%;
  }
  .fc-bg table {
    // height: 100%;
  }
  .fc-row.fc-rigid .fc-content-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .fc-row {
    position: relative;
  }
  .fc-bg table td {
    // border: 1px solid;
  }

  td.fc-head-container {
    padding-bottom: 0;
  }

  th.fc-day-header {
    // border: 1px solid;
  }

  td.fc-head-container table {
    // margin-bottom: -2px;
  }
  .fc-day-grid-event {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    width: calc(100% - 10px);
    span {
      text-align: left;
    }
  }
  // tbody.fc-body {
  //   border: 1px solid;
  // }
  // .fc-row.fc-week.fc-widget-content.fc-rigid:not(:last-child) {
  //     border-bottom: 1px solid;
  // }
  // th.fc-day-header:not(:first-child):after {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     width: 1px;
  //     background: #000;
  // }
  // .fc-view-container {
  //     position: relative;
  // }
  // .fc-h-event {
  //     background: #8c6f6f;
  //     position: relative;
  //     margin-left: 1px;
  // }
  // thead.fc-head {
  //     border: 1px solid #000;
  // }
}

.calendar-header {
  display: block;
  // margin-left: -50px;
  // margin-right: -50px;
  padding: 5px 15px;
  // margin-top: -30px;
  box-shadow: 0 2px 15px $black16;
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 99;
}
.calendar-inner {
  display: flex;
  justify-content: space-between;
}

.calendar-inner > div {
  display: flex;
  align-items: center;
}

.calendar-navigations .icons {
  display: flex;
  cursor: pointer;
}

.calendar-filters .inputs {
  white-space: nowrap;
  padding-left: 20px;
}
.calendar-filters .inputs .checkbox-label {
  max-width: unset;
}
.calendar-filters .inputs #views {
  width: 150px;
}
.week-view-wrapper {
  > div {
    table {
      .fc-event {
        text-align: left;
        background-color: $primary16w;
        border: 1px solid $primary;
        &.math_event {
          background-color: $warning25w;
          border: 1px solid $warning;
        }
        &.english_event {
          background-color: $theme-cyan25w;
          border: 1px solid $theme-cyan;
        }
        &.history_of_ukraine_event {
          background-color: $theme-green25w;
          border: 1px solid $theme-green;
        }
        &.international_history_event {
          background-color: $theme-blue25w;
          border: 1px solid $theme-blue;
        }
      }
    }
  }
  th.fc-axis,
  .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton td.fc-axis,
  .fc-bg td.fc-axis,
  .fc-time-grid .fc-slats td:first-child {
    width: 80px !important;
  }
  .fc-time-grid .fc-slats td:nth-child(2),
  td.fc-axis + td.fc-event-container,
  .fc-agenda-view
    .fc-day-grid
    .fc-row
    .fc-content-skeleton
    td.fc-event-container {
    width: calc(100% - 80px) !important;
  }

  .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton td:not(.fc-axis),
  .fc-bg td.fc-day {
    width: calc((100% - 80px) / 7) !important;
  }
  .fc-toolbar.fc-header-toolbar {
    display: none;
  }
  .fc-scroller.fc-time-grid-container {
    height: auto !important;
    overflow: hidden !important;
  }

  a.fc-v-event {
    width: auto;
  }

  .fc-day-header p {
    margin-bottom: 2px;
  }
  th.fc-axis.fc-widget-header {
    vertical-align: bottom;
  }
  .day-label p {
    font-size: 0.8em;
  }
  .date-label p {
    font-size: 1.2em;
    display: inline-block;
    min-width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 50%;
  }
  .fc-unthemed .fc-today {
    background: transparent;
  }
  .fc-today {
    .day-label p {
      color: $theme-blue;
    }
    .date-label p {
      background: $theme-blue;
      color: $white;
    }
  }
}
