@import './vars.scss';

.Class-constructor-wrapper {
  height: 100%;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  padding: 60px 40px;

  & .manage-students-container {
    border: 8px solid $color-dashboard-sidbar;
    border-radius: 10px;
    height: 100%;
    padding: 12px;
    margin-right: 25px;
    min-width: 500px;
  }

  & .manage-class-container {
    position: relative;
    width: 400px;

    & .btn-container {
      display: flex;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      top: -40px;

      & .add-class-btn {
        background: $color-dashboard-sidbar;
        border: 3px solid $color-gray-border;
        box-sizing: border-box;
        border-radius: 5px;
        color: $white;
      }
    }

    & .schoolClasses-container {
      display: grid;
      justify-items: center;

      & .schoolClass {
        width: 100%;
      }

      & .schoolClass-type {
        font-size: 25px;
      }

      & .schoolClass-panel {
        background: $color-dashboard-sidbar;
        color: white;
        position: relative;
        display: flex;
        justify-content: center;
        font-size: 38px;
        cursor: pointer;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 5px;

        & .up-down-icon {
          width: 50px;
          right: 0;
        }
      }

      & .schoolClass-students {
        margin-bottom: 10px;
        width: 100%;
        border: 8px solid $color-dashboard-sidbar;
        border-radius: 5px;
        padding: 10px;
      }
    }
  }

  & .students-container {
    display: grid;
    grid-gap: 5px;

    & .student-item {
      display: grid;
      grid-template: 33px / 1fr 66px min-content;
      align-items: center;
      border: 3px solid $color-gray-border;

      & > div {
        display: flex;
        align-items: center;
      }

      & .choose-class-container {
        padding: 0 8px;
        position: relative;

        & .choose-class-dropdown {
          position: relative;
          display: flex;
          cursor: pointer;
  
          & .up-down-icon {
            width: 27px;
            right: -25px;
            top: 1px;
          }
        }

        & .choose-class {
          position: absolute;
          top: 38px;
          left: 0;
          background: white;
          border: 6px solid $color-dashboard-sidbar;
          z-index: 9;
          border-radius: 5px;
          padding: 5px 0;
        }
      }


      & .with-border-right {
        height: 100%;
        border-right: 3px solid $color-gray-border;
      }

      & .horizontal-padding {
        padding: 0 10px;
        height: 100%;
      }

      & .checkbox-container {
        margin-bottom: 0;
        padding: 0 10px 0 40px;

        & .checkbox-checkmark {
          width: 24px;
          height: 24px;
          border:  3px solid $color-gray-border;
          top: 2px;
          left: 5px;
          
          &::after {
            left: 5px;
            top: -2px;
            width: 8px;
            height: 18px;
          }
        }

        & input:checked ~ .checkbox-checkmark {
          background-color: $color-gray-border;
        }
      }
    }
  }

  & .up-down-icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
  }

  & .edit-icon {
    position: absolute;
    height: 47px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-dashboard-sidbar;
    color: white;
    width: 47px;
    border-radius: 100%;

    &:hover {
      background: white;
      color: $color-dashboard-sidbar;
    }
  }
}
