@import './vars.scss';

.btn {
  font-family: $mainfont;
}
.btn-w-1 {
  width: 327px;
  max-width: calc(100% - 20px);
}
.btn-w-2 {
  width: 200px;
  max-width: calc(100% - 20px);
}
.btn-small-1 {
  font-size: $small-font;
  font-weight: bold;
  border-width: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.font-tin {
  font-family: $subfont;
}
.font-ar {
  font-family: $mainfont;
}

.section-gap {
  margin-top: 150px;
  margin-bottom: 150px;
}
.card-shadow {
  box-shadow: 0 0 50px 0 $black16;
  background-color: $white;
}

.banner-logo-padding {
  padding-top: 35vh;
}
.inputs {
  position: relative;
  .error-msg {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50px;
    min-width: 30%;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: $warning;
    font-family: Arsenal;
    font-size: $small-font;
    font-weight: bold;
    line-height: 1.29;
    color: $secondary;
    z-index: 9;
    @include rounded;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: -19px;
      border: 10px solid transparent;
      border-right-color: $warning;
    }
  }
  span.verifiedCheck {
    width: 7px;
    height: 16px;
    display: block;
    position: absolute;
    right: 30px;
    bottom: 19px;
    border-bottom: 2px solid $color-dashboard-sidbar;
    border-right: 2px solid $color-dashboard-sidbar;
    transform: rotate(45deg);
    &.has-eye {
      right: 65px;
    }
  }
  .search-icon {
    position: absolute;
    right: 0;
    z-index: 3;
    bottom: 2px;
    height: 47px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    cursor: pointer;
    color: $secondary;
    &.in-sticky {
      height: 53px;
    }
  }
  &.has-errors {
    .input {
      border-color: $warning;
    }
    .error-msg {
      display: flex;
    }
  }
  &.agree-input {
    .error-msg {
      height: 30px;
      top: 0;
      margin: auto;
    }
    @media (max-width: 470px) {
      &.has-errors {
        padding-top: 40px;
        .error-msg {
          top: 10px;
          bottom: auto;
          right: auto;
          left: 0;
          padding-left: 10px;
          padding-right: 10px;
          &::before {
            left: 0;
            right: 0;
            bottom: -10px;
            top: unset;
          }
        }
      }
      .checkbox-label {
        max-width: 100%;
      }
    }
  }
  &.select-input {
    .error-msg {
      height: 30px;
      bottom: 50px;
      margin: auto;
      &:before {
        bottom: -20px;
        top: unset;
        left: 0;
        right: 0;
        margin: auto;
        border-top-color: $warning;
        border-right-color: transparent;
      }
    }
  }
}
.input {
  height: 50px;
  border: 2px solid $color-dashboard-sidbar;
  color: black;
  @include rounded;
  padding: 16px;
  display: block;
  width: 100%;
  font-size: $small-font;
  &::placeholder {
    color: gray;
  }
  &:focus {
    outline: none;
  }
  // &.varified {
  //   background-color: rgba(184, 210, 61, 0.14);
  // }
}
.input-checkbox {
  display: none;
  & + .checkbox-span {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 10px;
    background-color: $white;
    border: 2px solid $primary;
    vertical-align: middle;
    margin-right: -1.6rem;
    position: relative;
    pointer-events: none;
    &::before {
      content: "";
      position: absolute;
      width: 0.4rem;
      height: 0.7rem;
      display: block;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      left: 0px;
      right: 0;
      top: 0px;
      bottom: 2px;
      margin: auto;
      transform: rotate(50deg);
    }
  }
  &:checked {
    + .checkbox-span {
      background-color: $primary;
    }
  }
}
.checkbox-label {
  cursor: pointer;
}
.label {
  font-family: Arsenal;
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: black;
  margin-top: 10px;
  margin-bottom: 6px;
  a {
    color: inherit;
    text-decoration: underline;
  }
  &.checkbox-label {
    font-family: Tinos;
    padding-left: 40px;
    font-weight: normal;
    max-width: 70%;
    display: inline-block;
    vertical-align: middle;
  }
  &.has-icon {
    svg {
      cursor: pointer;
    }
  }
  &.full {
    width: 100%;
  }
  &.image-label {
    position: relative;
    cursor: pointer;
  }
}

.password-tgl {
  width: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    box-shadow: 0px 1px 0 #c4beef;
    transform: rotate(-40deg) translateX(-6px);
    transform-origin: right top;
    opacity: 0;
  }
  &.show {
    &:before {
      opacity: 1;
    }
  }
}
.h100 {
  height: 100%;
}

.has-spinner {
  pointer-events: none;
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
  }
  &:before {
    background-image: url("./assets/icons/loading.svg");
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

button:focus {
  outline: none;
}

.button-submit {
  background: $color-gray-dark;
  border: 5px solid $color-dashboard-sidbar;
  border-radius: 10px;
  font-size: 23px;
  padding: 2px 16px;
  color: $white;
}

// .btn-green_border-white_background {
//   // border: 5px solid $color-dashboard-sidbar;
//   // border-radius: 10px;
//   // color: $black;
//   // background: $white;
// }

.input-green_border-white_background {
  // border: 5px solid $color-dashboard-sidbar;
  // border-radius: 10px;
  // color: $black;
  // background: $white;
  padding: 0 7px;
}

.input-black-border {
  border: 3px solid #464646;
  border-radius: 50px;
  color: $black;
  background: $white;
  height: 38px;
  padding: 0 14px;
}

.btn-black-border {
  border: 3px solid #464646;
  border-radius: 20px;
  color: $black;
  background: $white;
  padding: 0 14px;
  font-size: 30px;
}

.btn-green_border-white_background {
  border: 5px solid $color-dashboard-sidbar;
  border-radius: 10px;
  cursor: pointer;
  color: $black;
  background: $white;
  height: 40px;
  transition: 400ms;
  &:hover {
    background: $color-dashboard-sidbar;
  }
  &:disabled {
    opacity: 0.7;
  }
}

.input-green_border-white_background {
  border: 5px solid $color-dashboard-sidbar;
  border-radius: 10px;
  color: $black;
  background: $white;
  height: 50px;
}

.dash-padd {
  padding: 20px 60px;
}

// .css-e110bw {
//   top: 80px !important;
// }

/* The container */
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  background-color: $white;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
// .checkbox-container input:checked ~ .checkbox-checkmark {
//   background-color: $color-dashboard-sidbar;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {

  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-container {
  display: flex;
  justify-content: center;
}

.lesson-title {
  font-size: 20px;
  text-align: center;
  padding: 20px;
}

.settings-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;

  & .filter-item {
    min-width: 200px;
  }

  & span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
  }

  & .settings-icon {
    cursor: pointer;
  }
}

.settings-list {
  position: absolute;
  right: -145px;
  z-index: 9;
  padding: 5px 0;
  background: white;
  border: 6px solid $color-dashboard-sidbar;
  border-radius: 5px;
  
    & .with-sub-list {
      position: relative;
  
      & .left-right-icon {
        position: absolute;
        top: 2px;
        right: -2px;
      }
  
      & .sub-list {
        position: absolute;
        top: 0;
        left: 118px;
        z-index: 9;
        padding: 5px 0;
        background: white;
        border: 6px solid $color-dashboard-sidbar;
        border-radius: 5px;
        color: black;
  
        & ul {
          margin: 0;
          padding-inline-start: 0;
          list-style-type: none;
        }
      }
    }
}

.list {
  margin: 0;
  padding-inline-start: 0;
  list-style-type: none;
  & .list-item {
    cursor: pointer;
    padding: 3px 10px;
    white-space: nowrap;
    color: black;
    &:hover {
      background: $color-dashboard-sidbar;
      color: white;
    }
  }
}

.iframe-container, .image-container {
  display: flex;
  margin: 20px 0;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.LessonLinkInTable {
  display: grid !important;
  grid-template: 1fr / 1fr;
  text-align: center;
}

.login-form-button {
  border-radius: 40px;
  border: none;
  box-shadow: 5px 10px 4px rgba(0, 0, 0, 0.3);
  background: #B6D433;
  color: white;
  font-weight: bold;
}

.gallery-modal {
  z-index: 99999999999!important;
}

.custom-control-label {
  font-size: 1.5rem;

  &::after, &::before {
    top: 0.75rem;
  }
}

.ControlPanel {
  display: flex;
  height: 50px;
  padding: 10px;
  width: 100%;
  background: $color-dashboard-sidbar;
  align-items: center;

  &__btn {
    background: white;
    color: #465403;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    transition: box-shadow 300ms;
    white-space: nowrap;

    &.with-margin {
      margin-right: 20px;
    }

    &:hover {
      box-shadow: 2px 2px 5px 1px #809420; 
    }
  }

  &__filters {
    display: grid;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    grid-template: 1fr / 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 0 0 0 10px;

    & .input-search {
      height: 100%;
      padding: 10px;
    }
  }
}