.card-type-2 {
  box-shadow: 0 0 10px 0 $black16;
  background-color: $white;
  .card-header {
    background: $secondary;
    color: $white;
    padding: 0px 10px;
    border-radius: 0;
    h4 {
      margin: 0;
      padding-bottom: 4px;
      padding-top: 4px;
      display: block;
      font-family: $mainfont;
    }
    &.has-right-btn {
      display: flex;
      justify-content: space-between;
      .cart-right-btns {
        display: flex;
        align-items: center;
        & > div {
          padding: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .card-body {
    padding: 0 10px 30px;
  }
  &.full-height-card {
    height: calc(100vh - 200px);
    .card-body-wrapper {
      height: calc(100% - 55px);
      overflow: auto;
      .manage-card-inner-body {
        position: relative;
      }
      .inputs {
        position: sticky;
        padding-bottom: 5px;
        background-color: $white;
        top: 0px;
      }
    }
  }
  &.h100 {
    .card-body-wrapper {
      height: calc(100% - 55px);
      .card-body {
        height: 100%;
      }
    }
    &.noheader {
      .card-body-wrapper {
        height: 100%;
        .card-body {
          padding: 30px 30px 30px 50px;
        }
      }
      &.closed {
        .card-body {
          padding: 0;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.user-selector-wrapper {
  width: 180px;
  max-width: 100%;
  margin-bottom: 20px;
}
.user-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-height: 121px;
  background: $primary16;
  margin-bottom: 15px;
  margin-top: 15px;
  .user-card-role,
  .user-card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100% - 100px);
    &.has-student-icons {
      justify-content: flex-start;
    }
  }
  .user-card-role .btn + .btn {
    margin-top: 10px;
  }
  p {
    margin-bottom: unset;
  }
  .small-info {
    font-size: $small-font;
  }
  .user-card-email {
    font-size: 0.8rem;
    line-height: 1.3;
    p {
      font-size: $small-font-2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.unapproved {
    background-color: $success;
  }
  .has-student-icons div {
    width: 100px;
    img {
      max-width: 50px;
      padding: 5px;
    }
  }
}
.inner-form-card {
  width: 568px;
  max-width: 100%;
  margin: auto;
  padding: 25px;
}
.card-toggler {
  display: flex;
  justify-content: space-between;
  .opened {
    width: calc(100% - 120px);
  }
  .closed {
    width: calc(80px);
    cursor: pointer;
    .card-body {
      padding: 0;
      position: relative;
    }
    .card-opener {
      transform: rotate(90deg);
      font-family: $mainfont;
      text-align: center;
      p {
        font-weight: bold;
        margin: auto;
      }
      &.right {
        transform: rotate(-90deg);
      }
    }
  }
}
