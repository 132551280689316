$color-menu: #c0bfbf;
$color-dashboard-sidbar: #b8d23d;
$color-dashboard-sidbar_hover: #ffffff;
$color-dashboard-sidbar-title: #575757;
$color-gray-border: #959595;
$color-gray-dark: #555555;

$height-menu: 78px;


// $indicator-green: #78A738;
$indicator-green: #77a737;
$indicator-blue: #34C6CD;
$indicator-yellow: #CFFF00; 
$indicator-white: #fff;
$indicator-gray: #CCCCCC;
$indicator-red: #FFA266;
$indicator-alevioletred: #db7093;