@import './vars.scss';

.Profile {
  display: grid;
  grid-gap: 2px;
  padding: 30px 40px;

  & .SetAvatar {
    padding: 10px;
    background: $color-dashboard-sidbar;
    display: flex;
    justify-content: center;
    
    & .icon-container {
    color: white;
    cursor: pointer;
    }
  }

  & .SetNewPasswordLink {
    color: white;
  }
}

// .avatar-remove {
//   color: $pure-red;
//   &:hover {
//     color: $theme-red;
//   }
// }
// span.avatar-remove {
//   width: 40px;
//   height: 40px;
//   display: flex;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   padding: 6px;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// }
